<template>
  <div class="page1">
    <!-- 主体内容 -->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="一级分类">
                <el-select v-model="formInline.titleId" filterable placeholder="请选择一级分类">
                  <el-option label="全部" value></el-option>
                  <el-option
                    v-for="item in typeOneList"
                    :key="item.classifyId"
                    :label="item.classifyOneName"
                    :value="item.classifyId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="二级分类">
                <el-input
                  v-model="formInline.titleTwoName"
                  maxlength="20"
                  placeholder="请输入二级分类"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                v-if="$route.meta.authority.subPage.setting.tabs.complainSeconds.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-if="$route.meta.authority.subPage.setting.tabs.complainSeconds.button.add"
                @click="add()"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button type="info" @click="complainManageTag()">返回</el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <!-- v-if='$route.meta.authority.button.view || $route.meta.authority.button.edit || $route.meta.authority.button.configure' -->
          <el-table-column
            label="操作"
            align="center"
            v-if="
              $route.meta.authority.subPage.setting.tabs.complainSeconds.button.detail ||
              $route.meta.authority.subPage.setting.tabs.complainSeconds.button.edit
            "
            width="90"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    command="a"
                    v-if="$route.meta.authority.subPage.setting.tabs.complainSeconds.button.detail"
                    >查看</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="b"
                    v-if="$route.meta.authority.subPage.setting.tabs.complainSeconds.button.edit"
                    >编辑</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "levelTwo",
  data() {
    return {
      formInline: {
        titleId: "", // 一级分类的id
        typeOneName: "",
        titleTwoName: "",
      },
      loading: false,
      tableCols: [
        {
          prop: "classifyOneName",
          label: "一级分类",
          width: "",
        },
        {
          prop: "classifyTwoName",
          label: "二级分类",
          width: "",
        },
        {
          prop: "remarks",
          label: "备注",
          width: "",
        },
      ],
      tableData: [],
      typeOneList: [],
      page: 0,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    complainManageTag() {
      if (this.$store.state.complainManageTag == false) {
        this.$store.commit("SET_ComplainManageTag", true);
      }
    },
    searchData() {
      this.$axios
        .get("/acb/2.0/customerClassify/list", {
          data: {
            classifyOne: this.formInline.titleId,
            classify: this.formInline.titleTwoName,
            type: 2,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    add() {
      this.$router.push({
        path: "/levelTwoAddType",
        query: {
          operation: "添加",
        },
      });
    },
    handleCommand(cmd, data) {
      if (cmd === "a") {
        this.$router.push({
          path: "/seeTypeTwo",
          query: {
            operation: "查看",
            ...data,
          },
        });
      }
      if (cmd === "b") {
        // 编辑
        this.$router.push({
          path: "/levelTwoAddType",
          query: {
            operation: "编辑",
            ...data,
          },
        });
      }
    },
    getAllOneName() {
      this.$axios
        .get("/acb/2.0/customerClassify/list", {
          data: {
            type: 1,
            pageNum: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.typeOneList = res.value.list;
          }
        });
    },
  },
  mounted() {
    this.getAllOneName();
    this.searchData();
  },
  activated() {
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped></style>
